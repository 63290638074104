var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-button",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.connection || !_vm.connection.connected,
          expression: "!connection || !connection.connected",
        },
      ],
      attrs: {
        size: "small",
        type: _vm.buttonType(),
        icon: _vm.buttonIcon(),
        title: _vm.buttonTitle(),
      },
      on: { click: _vm.buttonClick },
    },
    [_vm._v(_vm._s(_vm.buttonText()))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }