var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Settings",
        visible: _vm.visible,
        width: "80%",
        "close-on-click-modal": false,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.refresh,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _vm.error
            ? _c(
                "el-alert",
                { attrs: { type: "error", title: "Error", "show-icon": "" } },
                [_vm._v(" " + _vm._s(_vm.error.message) + " ")]
              )
            : _vm._e(),
          _vm.server
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: this,
                    rules: _vm.rules,
                    disabled: _vm.saving,
                  },
                },
                [
                  _c(
                    "el-tabs",
                    { attrs: { "tab-position": "top" } },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "SMTP Server" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Hostname",
                                prop: "server.hostName",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.server.hostName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.server, "hostName", $$v)
                                  },
                                  expression: "server.hostName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Port Number",
                                prop: "server.portNumber",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 1,
                                  max: 65535,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.server.portNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.server, "portNumber", $$v)
                                  },
                                  expression: "server.portNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Allow Remote Connections",
                                prop: "server.allowRemoteConnections",
                              },
                            },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.server.allowRemoteConnections,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server,
                                      "allowRemoteConnections",
                                      $$v
                                    )
                                  },
                                  expression: "server.allowRemoteConnections",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "IMAP Server" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Port Number",
                                prop: "server.imapPortNumber",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 1,
                                  max: 65535,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.server.imapPortNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.server, "imapPortNumber", $$v)
                                  },
                                  expression: "server.imapPortNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "Limits" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "# of Messages to Keep",
                                prop: "server.numberOfMessagesToKeep",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1, "controls-position": "right" },
                                model: {
                                  value: _vm.server.numberOfMessagesToKeep,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server,
                                      "numberOfMessagesToKeep",
                                      $$v
                                    )
                                  },
                                  expression: "server.numberOfMessagesToKeep",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "# of Sessions to Keep",
                                prop: "server.numberOfSessionsToKeep",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1, "controls-position": "right" },
                                model: {
                                  value: _vm.server.numberOfSessionsToKeep,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server,
                                      "numberOfSessionsToKeep",
                                      $$v
                                    )
                                  },
                                  expression: "server.numberOfSessionsToKeep",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "Message Relay" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "Message Relay Enabled",
                                prop: "isRelayEnabled",
                              },
                            },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.isRelayEnabled,
                                  callback: function ($$v) {
                                    _vm.isRelayEnabled = $$v
                                  },
                                  expression: "isRelayEnabled",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRelayEnabled,
                                  expression: "isRelayEnabled",
                                },
                              ],
                              attrs: {
                                label: "SMTP server",
                                prop: "server.relayOptions.smtpServer",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.server.relayOptions.smtpServer,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server.relayOptions,
                                      "smtpServer",
                                      $$v
                                    )
                                  },
                                  expression: "server.relayOptions.smtpServer",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRelayEnabled,
                                  expression: "isRelayEnabled",
                                },
                              ],
                              attrs: {
                                label: "SMTP port",
                                prop: "server.relayOptions.smtpPort",
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: {
                                  min: 1,
                                  max: 65535,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.server.relayOptions.smtpPort,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server.relayOptions,
                                      "smtpPort",
                                      $$v
                                    )
                                  },
                                  expression: "server.relayOptions.smtpPort",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRelayEnabled,
                                  expression: "isRelayEnabled",
                                },
                              ],
                              attrs: {
                                label: "Tls mode",
                                prop: "server.relayOptions.tlsMode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.server.relayOptions.tlsMode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.server.relayOptions,
                                        "tlsMode",
                                        $$v
                                      )
                                    },
                                    expression: "server.relayOptions.tlsMode",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "None",
                                    attrs: { label: "None", value: "None" },
                                  }),
                                  _c("el-option", {
                                    key: "Auto",
                                    attrs: { label: "Auto", value: "Auto" },
                                  }),
                                  _c("el-option", {
                                    key: "SslOnConnect",
                                    attrs: {
                                      label: "SslOnConnect",
                                      value: "SslOnConnect",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "StartTls",
                                    attrs: {
                                      label: "StartTls",
                                      value: "StartTls",
                                    },
                                  }),
                                  _c("el-option", {
                                    key: "StartTlsWhenAvailable",
                                    attrs: {
                                      label: "StartTlsWhenAvailable",
                                      value: "StartTlsWhenAvailable",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRelayEnabled,
                                  expression: "isRelayEnabled",
                                },
                              ],
                              attrs: {
                                label: "Login",
                                prop: "server.relayOptions.login",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.server.relayOptions.login,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server.relayOptions,
                                      "login",
                                      $$v
                                    )
                                  },
                                  expression: "server.relayOptions.login",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRelayEnabled,
                                  expression: "isRelayEnabled",
                                },
                              ],
                              attrs: {
                                label: "Password",
                                prop: "server.relayOptions.password",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "password" },
                                model: {
                                  value: _vm.server.relayOptions.password,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server.relayOptions,
                                      "password",
                                      $$v
                                    )
                                  },
                                  expression: "server.relayOptions.password",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRelayEnabled,
                                  expression: "isRelayEnabled",
                                },
                              ],
                              attrs: {
                                label:
                                  "Sender Address - leave blank to use original sender",
                                prop: "server.relayOptions.senderAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.server.relayOptions.senderAddress,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.server.relayOptions,
                                      "senderAddress",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "server.relayOptions.senderAddress",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isRelayEnabled,
                                  expression: "isRelayEnabled",
                                },
                              ],
                              attrs: {
                                label: "Auto-Relay Recipients",
                                prop: "isRelayEnabled",
                              },
                            },
                            [
                              _vm._l(
                                _vm.server.relayOptions.automaticEmails,
                                function (email, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "relayOptionsAutomaticEmails[" +
                                              index +
                                              "].value",
                                            rules: {
                                              required: true,
                                              message: "Required",
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              model: {
                                                value:
                                                  _vm.server.relayOptions
                                                    .automaticEmails[index],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.server.relayOptions
                                                      .automaticEmails,
                                                    index,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "server.relayOptions.automaticEmails[index]",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { slot: "append" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.server.relayOptions.automaticEmails.splice(
                                                        index,
                                                        1
                                                      )
                                                    },
                                                  },
                                                  slot: "append",
                                                },
                                                [_vm._v("Remove")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.server.relayOptions.automaticEmails.push(
                                        ""
                                      )
                                    },
                                  },
                                },
                                [_vm._v("New Auto-Relay Recipient")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.saving, loading: _vm.saving },
              on: { click: _vm.save },
            },
            [_vm._v("Save")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }