var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "fill hfillpanel" },
    [
      _c(
        "el-header",
        { attrs: { height: "35" } },
        [
          _c("h1", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://github.com/rnwood/smtp4dev/",
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  attrs: { height: "35", src: "logo.png", alt: "smtp4dev" },
                }),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "padding-left": "10px",
                "padding-top": "5px",
                "margin-left": "10px",
                width: "30em",
                display: "inline-block",
              },
            },
            [_c("VersionInfo")],
            1
          ),
          _c("el-button", {
            staticStyle: {
              float: "right",
              "font-size": "1.7em",
              padding: "6px",
              margin: "0 3px",
            },
            attrs: { circle: "", icon: "el-icon-setting", title: "Settings" },
            on: {
              click: function ($event) {
                _vm.settingsVisible = true
              },
            },
          }),
          _vm._v("  "),
          _c("hubconnstatus", {
            staticStyle: { float: "right" },
            attrs: { connection: _vm.connection },
          }),
          _c("serverstatus", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.connection && _vm.connection.connected,
                expression: "connection && connection.connected",
              },
            ],
            staticStyle: { float: "right" },
            attrs: { connection: _vm.connection },
          }),
        ],
        1
      ),
      _c("settingsdialog", {
        attrs: { visible: _vm.settingsVisible, connection: _vm.connection },
        on: {
          closed: function ($event) {
            _vm.settingsVisible = false
          },
        },
      }),
      _c(
        "el-main",
        { staticClass: "fill vfillpanel" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "fill",
              attrs: { id: "maintabs", value: "messages", type: "border-card" },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticClass: "vfillpanel",
                  attrs: { label: "Messages", name: "messages" },
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "el-icon-message" }),
                    _vm._v(" Messages "),
                  ]),
                  _c(
                    "splitpanes",
                    {
                      staticClass: "default-theme fill",
                      on: {
                        resize: function ($event) {
                          _vm.messageListPaneSize = $event[0].size
                        },
                      },
                    },
                    [
                      _c(
                        "pane",
                        {
                          staticClass: "hfillpanel",
                          attrs: { size: _vm.messageListPaneSize },
                        },
                        [
                          _c("messagelist", {
                            staticClass: "fill",
                            attrs: { connection: _vm.connection },
                            on: {
                              "selected-message-changed":
                                _vm.selectedMessageChanged,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "pane",
                        {
                          staticClass: "hfillpanel",
                          attrs: { size: 100 - _vm.messageListPaneSize },
                        },
                        [
                          _c("messageview", {
                            staticClass: "fill",
                            attrs: { "message-summary": _vm.selectedMessage },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  staticClass: "vfillpanel",
                  attrs: { label: "Sessions", name: "sessions" },
                },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "el-icon-monitor" }),
                    _vm._v(" Sessions "),
                  ]),
                  _c(
                    "splitpanes",
                    {
                      staticClass: "default-theme fill",
                      on: {
                        resize: function ($event) {
                          _vm.sessionListPaneSize = $event[0].size
                        },
                      },
                    },
                    [
                      _c(
                        "pane",
                        {
                          staticClass: "vfillpanel",
                          attrs: { size: _vm.sessionListPaneSize },
                        },
                        [
                          _c("sessionlist", {
                            staticClass: "fill",
                            attrs: { connection: _vm.connection },
                            on: {
                              "selected-session-changed":
                                _vm.selectedSessionChanged,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "pane",
                        {
                          staticClass: "vfillpanel",
                          attrs: { size: 100 - _vm.sessionListPaneSize },
                        },
                        [
                          _c("sessionview", {
                            staticClass: "fill",
                            attrs: { "session-summary": _vm.selectedSession },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }