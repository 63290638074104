var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.headers,
          expression: "headers",
        },
      ],
      staticClass: "headers",
    },
    [
      _c(
        "el-table",
        {
          staticClass: "table fill",
          attrs: {
            data: _vm.headers,
            "empty-text": "No headers",
            "default-sort": { prop: "name", order: "ascending" },
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              property: "name",
              label: "Name",
              width: "150",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: { property: "value", label: "Value", sortable: "" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }