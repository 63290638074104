var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("aceeditor", {
        attrs: { lang: "text", theme: "chrome", width: "100%", height: "100%" },
        on: { init: _vm.editorInit },
        model: {
          value: _vm.textToShow,
          callback: function ($$v) {
            _vm.textToShow = $$v
          },
          expression: "textToShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }