var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sessionlist" },
    [
      _c(
        "div",
        { staticClass: "toolbar" },
        [
          _c(
            "confirmationdialog",
            {
              attrs: {
                "always-key": "deleteAllSessions",
                message: "Are you sure you want to delete all sessions?",
              },
              on: { confirm: _vm.clear },
            },
            [
              _c("el-button", {
                attrs: { icon: "el-icon-close", title: "Clear" },
              }),
            ],
            1
          ),
          _c("el-button", {
            attrs: {
              icon: "el-icon-delete",
              disabled: !_vm.selectedsession,
              title: "Delete",
            },
            on: { click: _vm.deleteSelected },
          }),
          _c("el-button", {
            attrs: {
              icon: "el-icon-refresh",
              disabled: _vm.loading,
              title: "Refresh",
            },
            on: { click: _vm.refresh },
          }),
        ],
        1
      ),
      _vm.error
        ? _c(
            "el-alert",
            { attrs: { type: "error", title: "Error", "show-icon": "" } },
            [
              _vm._v(" " + _vm._s(_vm.error.message) + " "),
              _c("el-button", { on: { click: _vm.refresh } }, [
                _vm._v("Retry"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticClass: "table",
          attrs: {
            data: _vm.sessions,
            "empty-text": "No sessions",
            "highlight-current-row": "",
            "default-sort": { prop: "endDate", order: "descending" },
            type: "selection",
            "reserve-selection": "true",
            "row-key": "id",
            stripe: "",
          },
          on: { "current-change": _vm.handleCurrentChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              property: "endDate",
              label: "End Date",
              width: "120",
              sortable: "",
              formatter: _vm.formatDate,
            },
          }),
          _c("el-table-column", {
            attrs: {
              property: "clientAddress",
              label: "Client Address",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              property: "numberOfMessages",
              label: "# Msgs",
              width: "120",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.terminatedWithError
                      ? _c("i", {
                          staticClass: "el-icon-warning",
                          attrs: {
                            title: "This session terminated abnormally",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" " + _vm._s(scope.row.numberOfMessages) + " "),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }