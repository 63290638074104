var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.attachments,
          expression: "attachments",
        },
      ],
      staticClass: "vfillpanel pad",
    },
    _vm._l(_vm.attachments, function (attachment) {
      return _c(
        "div",
        { key: attachment.id },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-paperclip",
                size: "small",
                type: "primary",
                round: "",
              },
              on: {
                click: function ($event) {
                  return _vm.openAttachment(attachment)
                },
              },
            },
            [_vm._v(_vm._s(attachment.fileName))]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }